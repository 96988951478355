import "./WaitingGraphic.scss";

const WaitingGraphic = () => {
    return (
        <div className="WaitingGraphic">
            <p className={"WaitingText"}>Waiting for TV...</p>
        </div>
    );
}

export default WaitingGraphic;