import Styles from "./ChaseController.module.scss";
import {useEffect, useState, useRef} from "react";
import Recorder from "../../components/Recorder/Recorder";
import axios from "axios";
import MultiChoiceSelectionButton from "../../components/AnswerButton/MultiChoiceSelectionButton";
import WaitingGraphic from "../../components/WaitingGraphic/WaitingGraphic";
import HeadingBox from "../../components/HeadingBox/HeadingBox";
import NoSleep from "nosleep.js"

const ChaseController = () => {
    const [roomId, setRoomId] = useState("")
    const [clientId, setClientId] = useState("")
    const [connectionStatus, setConnectionStatus] = useState("disconnected")
    const [error, setError] = useState(null)
    const [recordedAudio, setRecordedAudio] = useState("");
    const [waitingForTranscription, setWaitingForTranscription] = useState(false);
    const server = useRef(null)
    const gameStateRef = useRef(null); // Create ref for gameState
    const [isRecording, setIsRecording] = useState(false);
    const noSleep = useRef(new NoSleep());

    const testState = {
        "cash": 90000,
        "chasersoffers": [
            90000,
            17000,
            11000
        ],
        "chasersposition": 8,
        "currentchaser": "Vixen",
        "currentquestiondata": {
            "accepted_answers": null,
            "actual_answer": "Hawaii",
            "id": 434,
            "keywords": null,
            "multi_choice_answers": [
                {
                    "answer": "Texas",
                    "is_correct": false
                },
                {
                    "answer": "Florida",
                    "is_correct": false
                },
                {
                    "answer": "Hawaii",
                    "is_correct": true
                }
            ],
            "question": "In which US state was 'Magnum P.I.' set?",
            "system_hint": null,
            "voice_over_answer_url": null,
            "voice_over_question_url": "https://roku-play360-us.s3.us-east-1.amazonaws.com/the-chase/audio/voice-over/questions/multi-choice/output_vo_434.wav"
        },
        "finalchasetarget": 12,
        "playersposition": 6,
        "state": "StudioIntro",
        "rendergraphic": true
    }

    const [gameState, setGameState] = useState(testState);
    gameStateRef.current = gameState;


    useEffect(() => {
        const enableNoSleep = () => {
            noSleep.current.enable();
        };

        document.addEventListener("click", enableNoSleep, {once: true});

        return () => {
            noSleep.current.disable();
            document.removeEventListener("click", enableNoSleep);
        };
    }, []);

    useEffect(() => {
        gameStateRef.current = gameState; // Update ref whenever question changes
    }, [gameState]);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const roomId = query.get("roomId");
        setRoomId(roomId);
        console.log("roomId: " + roomId);
        if (roomId) connect(roomId);
    }, []);

    const connect = (roomId) => {
        const ws = new WebSocket(process.env.REACT_APP_WS_SERVER);
        server.current = ws;
        setConnectionStatus("connecting");
        ws.onopen = () => {
            console.log("connected");
            const message = {
                type: "join_room",
                roomId: roomId
            }
            const test = JSON.stringify(message);
            console.log(test);
            setConnectionStatus("joining_room");
            ws.send(test);
        };

        ws.onmessage = (event) => {

            const message = JSON.parse(event.data);
            switch (message.type) {
                case "connected":
                    console.log("your ID: " + message.client_id);
                    setClientId(message.client_id);
                    break;
                case "room_joined":
                    setConnectionStatus("connected");
                    console.log("room %s joined", roomId);
                    setGameState(message.state);
                    break;
                case "join_room_failed":
                    console.log("room join error");
                    setConnectionStatus("connected - failed to join room");
                    break;
                case "error":
                    setError(message.error);
                    console.log(message.error);
                    break;
                case "state_change":
                    setGameState(message.state);
                    console.log(message.state)
                    break;
                default:
                    console.log("unknown message type: " + event.data);
                    break;
            }
        };

        ws.onclose = (event) => {
            console.log("disconnected");
            console.log(event);
            setConnectionStatus("disconnected");
        }
    }

    //const sendMessage = (e) => {
    //    e.preventDefault();

    //    const form = e.target;
    //    const formData = new FormData(form);
    //    const message = formData.get("message");

    //    console.log("button clicked");
    //    server.current.send(JSON.stringify({ type: "broadcast", message: message }));
    //}

    const handleRecordingEnd = async (base64Audio) => {
        setRecordedAudio(base64Audio);
        const data = await transcribeAudio(gameStateRef.current.currentquestiondata, base64Audio);

        const message = {
            "type": "broadcast",
            "message": {
                "type": "answer",
                "data": {
                    "deepgramResult": data
                }
            }
        }

        setIsRecording(false);
        server.current.send(JSON.stringify(message));
    };

    const handleStartRecording = () => {
        setIsRecording(true);
        console.log("Recording started...");
        const message = {
            "type": "broadcast",
            "message": {
                "type": "recording",
                "client": clientId,
            }
        }

        server.current.send(JSON.stringify(message));

    }

    const transcribeAudio = async (question, base64Audio) => {
        setWaitingForTranscription(true);
        //const httpsAgent = new https.Agent({ rejectUnauthorized: false });
        const url = process.env.REACT_APP_API_URL + "/dg/transcribe";
        const body = JSON.stringify({question: question, audio_base64: base64Audio});
        console.log("Transcribing:", body);
        const response = await axios.post(
            url,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "c1aed386d72e43b5a48852e68c57b5e0",
                },
                //httpsAgent,
            }
        );
        console.log("Transcription:", response);
        setWaitingForTranscription(false);
        return response.data;
    }

    const handleAnswerClicked = (answer) => {
        const message = {
            "type": "broadcast",
            "message": {
                "type": "answer",
                "data": {
                    "selected": answer
                }
            }
        }
        server.current.send(JSON.stringify(message));
    }

    const handleSkipVideo = () => {
        const message = {
            "type": "broadcast",
            "message": {
                "type": "skip"
            }
        }
        server.current.send(JSON.stringify(message));
    }

    const renderView = () => {
        const gameState = gameStateRef.current;

        if (gameState.rendergraphic === false) {
            return WaitingView();
        }

        switch (gameState.state) {
            case "CashBuilder":
                return <RecorderView
                    handleRecordingEnd={handleRecordingEnd}
                    handleStartRecording={handleStartRecording}
                    waitingForTranscription={waitingForTranscription}
                />
            case "FinalChase" :
                return <RecorderView
                    handleRecordingEnd={handleRecordingEnd}
                    handleStartRecording={handleStartRecording}
                    waitingForTranscription={waitingForTranscription}
                />
            case "Chase":
                return <AnswersView question={gameState.currentquestiondata} onClick={handleAnswerClicked}/>;
            case "ChooseChase":
                return <CashTreeView
                    highOffer={gameState.chasersoffers[0]}
                    cash={gameState.chasersoffers[1]}
                    lowOffer={gameState.chasersoffers[2]}
                    onClick={handleAnswerClicked}
                />;
            case "StudioIntro" :
            case "ChaserIntro" :
            case "FinalChaseIntro" :
                return <SingleButtonView onClick={handleSkipVideo}/>;
            default:
                return;
        }
    }

    const renderHeading = () => {

        const gameState = gameStateRef.current;

        if (gameState.rendergraphic === false) {
            return;
        }
        switch (gameState.state) {
            case "CashBuilder":
                return <HeadingView content={isRecording ? "RECORDING..." : "HOLD TO RECORD"}
                                    isRecording={isRecording}/>
            case "FinalChase":
                return <HeadingView content={"HOLD TO RECORD"}/>
            case "Chase":
                return <HeadingView content={"SELECT ANSWER"}/>
            case "ChooseChase":
                return <HeadingView content={"SELECT OFFER"}/>
            default:
                return;
        }
    }

    return (
        <div className={`${Styles.Background} ${Styles.Blue}`}>
            <div className={`${Styles.Background} ${Styles.Red} ${isRecording && Styles.Show}`}/>
            <div className={Styles.Vignette}>
                <div className={Styles.ChaseController}>
                    <div className={Styles.ChaseControllerGradient}/>
                    <div className={Styles.Header}>
                        <img className={Styles.Logo} src={"../img/Chase_Logo.svg"} alt={"Logo"}/>
                    </div>

                    {renderHeading()}
                    {renderView()}

                    <div className={Styles.RoomStatusContainer}>
                        <div className={Styles.RoomStatus_WhiteBorder}>
                            <div className={Styles.RoomStatus_BlueBorder}>
                                <div className={Styles.RoomStatus_MetallicGradient}>
                                    <div className={Styles.RoomStatus_InnerGradient}>
                                        <p className={Styles.RoomInfoLabel}>{connectionStatus === "connected" ? roomId : "Room name"}</p>
                                        <div
                                            className={`${Styles.ConnectionStatusDot} ${connectionStatus === "connected" && Styles.connected}`}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {error && <p style={{color: "red"}}>Error: {error}</p>}

                </div>
            </div>
        </div>
    )
}

//VIEWS 
const WaitingView = () => {
    return <div className={Styles.WaitingView}>
        <WaitingGraphic/>
    </div>
}
const HeadingView = ({content, isRecording}) => {
    return <HeadingBox className={Styles.HeadingView} content={content} isRecording={isRecording}
                       type={"subHeading"}/>
}

const CashTreeView = ({lowOffer, cash, highOffer, onClick}) => {
    return <div className={Styles.AnswerButtonsWrapper}>
        <div className={Styles.AnswerButtons_WhiteBorder}>
            <div className={Styles.AnswerButtons_BlueBorder}>
                <div className={Styles.AnswerButtons_MetallicGradient}>
                    <div className={Styles.AnswerButtons_InnerGradient}>
                        <MultiChoiceSelectionButton buttonType={"cash"} onClick={onClick} answer={highOffer}
                                                    index={0}/>
                        <MultiChoiceSelectionButton buttonType={"cash"} onClick={onClick} answer={cash} index={1}/>
                        <MultiChoiceSelectionButton buttonType={"cash"} onClick={onClick} answer={lowOffer}
                                                    index={2}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const SingleButtonView = ({onClick}) => {
    return <div className={Styles.AnswerButtonsWrapper}>
        <MultiChoiceSelectionButton buttonType={"skip"} onClick={onClick} answer={"Skip"} index={0}/>
    </div>
}

const AnswersView = ({question, onClick}) => {

    return <div className={Styles.AnswerButtonsWrapper}>
        <div className={Styles.AnswerButtons_WhiteBorder}>
            <div className={Styles.AnswerButtons_BlueBorder}>
                <div className={Styles.AnswerButtons_MetallicGradient}>
                    <div className={Styles.AnswerButtons_InnerGradient}>
                        <MultiChoiceSelectionButton buttonType={"answer"} onClick={onClick}
                                                    answer={question.multi_choice_answers[0].answer} index={0}/>
                        <MultiChoiceSelectionButton buttonType={"answer"} onClick={onClick}
                                                    answer={question.multi_choice_answers[1].answer} index={1}/>
                        <MultiChoiceSelectionButton buttonType={"answer"} onClick={onClick}
                                                    answer={question.multi_choice_answers[2].answer} index={2}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const RecorderView = ({handleRecordingEnd, handleStartRecording, waitingForTranscription}) => {

    return <div className={Styles.RecorderWrapper}>
        <Recorder onRecordingEnd={handleRecordingEnd}
                  onStartRecording={handleStartRecording}
                  disabled={waitingForTranscription}/>
    </div>
}
export default ChaseController;