import "./HeadingBox.scss";

const HeadingBox = ({ content, type, isRecording }) => {

    return <div className={"Wrapper"}>
        <div className={"WhiteBorder"}>
            <div className={"BlueBorder"}>
                <div className={"MetallicGradient"}>
                    <div className={`InnerGradient ${isRecording ? "Red" : "Blue"}`}>

                        {type==="heading" && <p className={"Heading"}>{content}</p>}
                        {type==="subHeading" && <p className={"SubHeading"}>{content}</p>}
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default HeadingBox;