import {useState, useEffect, useRef} from "react";
import "./AnswerButton.scss";

const MultiChoiceSelectionButton = ({answer: content, index, onClick, buttonType}) => {

    return (
        <button className={"AnswerButton"} onClick={() => { onClick(index) }}>
            <div className="AnswerButtonReflection"/>
            <ButtonContent buttonType={buttonType} content={content} index={index}/>
        </button>
    );
};


function formatCash(cash) {
   return `£${parseInt(cash, 10).toLocaleString()}`;
}

const ButtonContent = ({buttonType, content, index}) => {

    switch (buttonType) {
        case "answer":
            let letters = ["A", "B", "C"]
            return (
                <div className="LabelContainer">
                    <p className="AnswerLetter">{letters[index]}</p>
                    <p className="AnswerText">{content}</p>
                </div>
            );
        case "cash":
            return (<div className="LabelContainer">
                    <p className="AnswerLetter">{}</p>
                    <p className="AnswerText">{formatCash(content)}</p>
                </div>
            );
            case "skip":
                return (
                    <div className="LabelContainer">
                        <p className="AnswerLetter">{}</p>
                        <p className="AnswerText">Skip</p>
                    </div>
                );
        default:
            return (
                <div className="LabelContainer">
                    <p className="AnswerLetter">{}</p>
                    <p className="AnswerText"></p>
                </div>
            );
    }
}


export default MultiChoiceSelectionButton;
