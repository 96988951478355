import {useState, useEffect, useRef} from "react";
import "./Recorder.scss";

const Recorder = ({onRecordingEnd, onStartRecording, disabled}) => {
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const streamRef = useRef(null);

    const recorderReference = useRef(null);

    useEffect(() => {
        const disableRightClick = (e) => {
            e.preventDefault();
        };

        if (recorderReference.current) {
            recorderReference.current.addEventListener("contextmenu", disableRightClick);
        }

        return () => {
            if (recorderReference.current) {
                recorderReference.current.removeEventListener("contextmenu", disableRightClick);
            }
        };
    }, []);

    useEffect(() => {
        const setupMediaRecorder = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({audio: true});
                streamRef.current = stream;

                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorderRef.current = mediaRecorder;

                mediaRecorder.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        audioChunksRef.current.push(event.data);
                    }
                };

                mediaRecorder.onstop = async () => {
                    const audioBlob = new Blob(audioChunksRef.current, {type: "audio/webm"});
                    const base64 = await convertBlobToBase64(audioBlob);

                    if (onRecordingEnd) {
                        onRecordingEnd(base64);
                    }

                    setIsRecording(false);
                };

                console.log("MediaRecorder initialized.");
            } catch (error) {
                console.error("Error accessing microphone:", error);
            }
        };

        setupMediaRecorder();

        return () => {
            if (streamRef.current) {
                streamRef.current.getTracks().forEach((track) => track.stop());
            }
        };
    }, []);

    const startRecording = () => {
        if (disabled) return
        if (mediaRecorderRef.current) {
            audioChunksRef.current = [];
            mediaRecorderRef.current.start();
            setIsRecording(true);

            if (onStartRecording) {
                onStartRecording();
            }

            if ("vibrate" in navigator) {
                navigator.vibrate(100);
            }
            
            console.log("Recording started...");
        }
    };

    const stopRecording = () => {
        if (!isRecording) return
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
            mediaRecorderRef.current.stop();
            console.log("Recording stopped.");
        }
    };

    const convertBlobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => resolve(reader.result.split(",")[1]);
            reader.onerror = reject;
        });
    };

    return (


        <button ref={recorderReference} className={"RecordButtonContainer"}
                onPointerDown={startRecording}
                onPointerUp={stopRecording}
                onPointerLeave={stopRecording}
                disabled={disabled}>

            <div className={`RecordButton_MetallicRed ${isRecording ? "pressed" : "stopping"}`}>
                <div className={"RecordButton_MetallicSilver"}>
                    <div className={"RecordButton_WhiteBorder"}>
                        <div className={`RecordButton_InnerGradient ${isRecording ? "pressed" : ""}`}>
                            <img className={"Icon"} src={"../img/mic_icon.svg"} alt={""}/>
                            
                        </div>
                    </div>
                </div>
            </div>
        </button>

    );
};

export default Recorder;
