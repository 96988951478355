import React from "react";
import {
    Routes,
    Route,
    Link
} from "react-router-dom";
import ChaseController from "./pages/ChaseController/ChaseController.jsx";
import PWFController from "./pages/PWFController/PWFController.jsx";

import Controller5 from "./pages/PWFControllerNew/Controller5.jsx"

export default function App() {
    React.useEffect(() => {
        const clearCache = async () => {
            if ('caches' in window) {
                const cacheNames = await caches.keys();
                await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
                console.log("Caches cleared");
            }
        };

        window.addEventListener('load', clearCache);

        return () => {
            window.removeEventListener('load', clearCache);
        };
    }, []);

    return (
        <div className="container">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/ChaseController" element={<ChaseController />} />
                <Route path="/PWFController" element={<Controller5 />} />
            </Routes>
        </div>
    );
}

function Home() {
    return (
        <>
            <h2>Home</h2>
            <Link to="/ChaseController">ChaseController</Link>
            <br />
            <Link to="/PWFController">PWFController</Link>
        </>
    );
}
