import React, { useEffect, useState, useRef, useCallback } from "react";
import './Controller.scss';

import arrowBlue from '../../assets/WhiteArrowBlue.png'
import blockBlue from '../../assets/BlueBlock.png'
import arrowYellow from '../../assets/WhiteArrowYellow.png'
import blockYellow from '../../assets/YellowBlock.png'
import arrowOrange from '../../assets/WhiteArrowOrange.png'
import blockOrange from '../../assets/OrangeBlock.png'
import bombImg from '../../assets/bomb.png'
import settingsImg from '../../assets/settings.png'
import joinGame from '../../assets/JoinGame.png'
export default function Controller() {
    const [isDragging, setIsDragging] = useState(false);
    const [currentPosition, setCurrentPosition] = useState(null);
    const [direction, setDirection] = useState(null);
    const joystickContainerRef = useRef(null);
    const [roomId, setRoomId] = useState("");
    const [PID, setPID] = useState("");
    const [ClientId, setClientId] = useState("");

    const serverRef = useRef(null);
    const [positionArray, setPositionArray] = useState([]);
    const [lastButton, setLastButton] = useState(null);
    const [activeButton, setActiveButton] = useState(null);
    const [mouseUpCount, setMouseUpCount] = useState(0);
    const [mouseDownCount, setMouseDownCount] = useState(0);
    const [showSettings, setShowSettings] = useState(false);
    const [viewWidth, setViewWidth] = useState(window.innerWidth);
    const [isBombButtonPressed, setIsBombButtonPressed] = useState(false);

    const [roomFull, setRoomFull] = useState(false)

    const clientIdRef = useRef(null);
    const pidRef = useRef(null);

    useEffect(() => {
        const clearCache = async () => {
            if ('caches' in window) {
                const cacheNames = await caches.keys();
                await Promise.all(
                    cacheNames.map(cacheName => caches.delete(cacheName))
                );
                console.log('Cache cleared');
            }
        };

        clearCache();
    }, []);
    useEffect(() => {
        const url = new URL(window.location.href);
        const pid = url.searchParams.get("pid");
        if (pid) {
            setPID(pid);
        }
        console.log("pid: " + pid);
    }, []);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const roomId = query.get("roomId");
        setRoomId(roomId);
        console.log("roomId: " + roomId);
        if (roomId) connect(roomId);
    }, []);

    useEffect(() => {
        if (isDragging) {
            handleSendDirection(null, direction, true);
        }
    }, [direction]);

    useEffect(() => {
        clientIdRef.current = ClientId;
    }, [ClientId])

    const connect = (roomId) => {
        const ws = new WebSocket("wss://server.voice.games");
        serverRef.current = ws;
        ws.onopen = () => {
            console.log("connected");
            const message = {
                type: "join_room",
                roomId: roomId
            };
            const test = JSON.stringify(message);
            console.log(test);
            ws.send(test);
        };

        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);
            console.log(message);
            switch (message.type) {
                case "connected":
                    setClientId(message.client_id);
                    console.log("your ID: " + message.client_id);
                    break;
                case "room_joined":
                    console.log("room %s joined", roomId);
                    break;
                case "player_assigned":
                    const cidPid = message.content.split("_");
                    if (cidPid[0] == clientIdRef.current) {
                        setPID(cidPid[1]);
                        console.log("I am player " + cidPid[1]);
                    }
                    console.log("player_assigned", message.content);
                    break;
                case "room_full":
                    if (message == ClientId) {
                        setRoomFull(true)
                    }
                    break
                case "lobby_reset": 
                    setPID("");
                case "error":
                    console.log(message.error);
                    break;
                default:
                    console.log("unknown message type");
                    break;
            }
        };
    };

    const handleSendDirection = (button, setBool) => {
        console.log(button)
        if (serverRef.current) {
            try {
                let direction;

                switch (button) {
                    case "1":
                        direction = "up";
                        break;
                    case "2":
                        direction = "right";
                        break;
                    case "3":
                        direction = "left";
                        break;
                    case "4":
                        direction = "down";
                        break;
                    default: break;
                }
                console.log("Sending direction", `${PID}_${direction}_${setBool}`)
                serverRef.current.send(JSON.stringify({ type: "broadcast", message: `${PID}_${direction}_${setBool}` }));
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleSendButton = useCallback((button, setBool) => {
        if (serverRef.current) {
            try {
                serverRef.current.send(JSON.stringify({ type: "broadcast", message: `${PID}_${button}_${setBool}` }));
            } catch (error) {
                console.log(error);
            }
        }
    }, [PID]);


    const handleMouseDown = (event) => {
        setIsDragging(true);
        handleButtonActivation(event);
    };

    const handleMouseMove = (event) => {
        if (isDragging) {
            handleButtonActivation(event);
        }
    };

    const handleMouseUp = (event) => {
        setIsDragging(false);
        if (activeButton) {
            handleSendDirection(activeButton, false);
            setActiveButton(null);
        }
    };

    const handleButtonActivation = (event) => {
        const containerRect = joystickContainerRef.current.getBoundingClientRect();
        const centerX = containerRect.left + containerRect.width / 2;
        const centerY = containerRect.top + containerRect.height / 2;

        const pointerX = event.clientX - centerX;
        const pointerY = event.clientY - centerY;

        const angle = (-45 * Math.PI) / 180;
        const localX = pointerX * Math.cos(angle) - pointerY * Math.sin(angle);
        const localY = pointerX * Math.sin(angle) + pointerY * Math.cos(angle);

        let button;
        if (localX < 0 && localY < 0) {
            button = "1";
        } else if (localX >= 0 && localY < 0) {
            button = "2";
        } else if (localX < 0 && localY >= 0) {
            button = "3";
        } else if (localX >= 0 && localY >= 0) {
            button = "4";
        }

        if (button && button !== activeButton) {

            setLastButton(activeButton);
            handleSendDirection(lastButton, false)

            setActiveButton(button);
            handleSendDirection(button, true)
        }
    };



    const handleTouchStart = (event) => {
        for (let i = 0; i < event.touches.length; i++) {
            handleMouseDown(event.touches[i]);
        }
    };

    const handleTouchMove = (event) => {
        for (let i = 0; i < event.touches.length; i++) {
            handleMouseMove(event.touches[i]);
        }
    };

    const handleTouchEnd = (event) => {
        for (let i = 0; i < event.changedTouches.length; i++) {
            handleMouseUp(event.changedTouches[i]);
        }
    };

    const goFullScreen = () => {
        const doc = document.documentElement;

        if (
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement
        ) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        } else {
            if (doc.requestFullscreen) {
                doc.requestFullscreen();
            } else if (doc.webkitRequestFullscreen) {
                doc.webkitRequestFullscreen();
            } else if (doc.mozRequestFullScreen) {
                doc.mozRequestFullScreen();
            } else if (doc.msRequestFullscreen) {
                doc.msRequestFullscreen();
            } else if (typeof window.HTMLElement !== "undefined" && HTMLElement.prototype.requestFullscreen) {
                HTMLElement.prototype.requestFullscreen.call(doc);
            }
        }
    };
    const handleBombButtonDown = () => {
        setIsBombButtonPressed(true);
        handleSendButton('b', true);
    };

    const handleBombButtonUp = () => {
        setIsBombButtonPressed(false);
        handleSendButton('b', false);
    };
    const handleShowSettings = () => {
        setShowSettings(!showSettings);
        environmentController("paused")
    }

    const getViewWidth = () => {
        return window.innerWidth;
    }

    useEffect(() => {
        const handleResize = () => {
            setViewWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const environmentController = (message) => {
        console.log("sending message", message)
        if (serverRef.current) {
            serverRef.current.send(JSON.stringify({ type: "broadcast", message: message }));

        }
    }

    const handleJoinGame = () => {
        
        environmentController(`join_game_${ClientId}`)
    }

    const handleQuitGame = () => {
        environmentController("quit")
        showSettings(false)
    }

    return (
        <>
            {getViewWidth() > 600 ? (
                <>
                    {PID ? (
                        <div className="new-controller-container" onTouchStart={(event) => event.preventDefault}>
                            <div className="debug-info">
                                <h1>Client = {ClientId}</h1>
                                <h1>PID = {PID}</h1>
                            </div>

                            <div
                                ref={joystickContainerRef}
                                className="dpad-container"
                                onPointerDown={(e) => {
                                    setIsDragging(true);
                                    handleButtonActivation(e);
                                }}
                                onPointerMove={(e) => {
                                    if (isDragging) {
                                        handleButtonActivation(e);
                                    }
                                }}
                                onPointerUp={(e) => {
                                    setIsDragging(false);
                                    if (activeButton) {
                                        handleSendButton(activeButton, false);
                                        setActiveButton(null);
                                    }
                                }}
                            >
                                <button className={activeButton === "1" ? "buttonActive" : ""}>
                                    <img src={activeButton != 1 ? blockBlue : blockOrange} style={{ transform: "rotate(-90deg)" }} className="block-img" alt="arrow" />
                                    <img src={activeButton != 1 ? arrowBlue : arrowOrange} style={{ transform: "rotate(-90deg)" }} alt="block" className="arrow-img" />
                                </button>
                                <button className={activeButton === "2" ? "buttonActive" : ""}>
                                    <img src={activeButton != 2 ? blockBlue : blockOrange} style={{ transform: "rotate(-90deg)" }} className="block-img" alt="arrow" />
                                    <img src={activeButton != 2 ? arrowBlue : arrowOrange} style={{ transform: "rotate(0deg)" }} alt="block" className="arrow-img" />
                                </button>
                                <button className={activeButton === "3" ? "buttonActive" : ""}>
                                    <img src={activeButton != 3 ? blockBlue : blockOrange} style={{ transform: "rotate(-90deg)" }} className="block-img" alt="arrow" />
                                    <img src={activeButton != 3 ? arrowBlue : arrowOrange} style={{ transform: "rotate(180deg)" }} alt="block" className="arrow-img" />
                                </button>
                                <button className={activeButton === "4" ? "buttonActive" : ""}>
                                    <img src={activeButton != 4 ? blockBlue : blockOrange} style={{ transform: "rotate(-90deg)" }} className="block-img" alt="arrow" />
                                    <img src={activeButton != 4 ? arrowBlue : arrowOrange} style={{ transform: "rotate(90deg)" }} alt="block" className="arrow-img" />
                                </button>
                            </div>

                            <button className="settings-button" onPointerDown={() => handleShowSettings()}>
                                <img src={settingsImg} />
                            </button>

                            {
                                showSettings &&
                                <div className="settings-modal">
                                    <div className="settings-modal-content">
                                        <button>Fullscreen</button>
                                        <div>
                                            <button onClick={() => environmentController("music")}>Music</button>
                                            <button onClick={() => environmentController("sfx")}>SFX</button>
                                        </div>
                                        <button onClick={() => handleQuitGame()}>Quit</button>
                                    </div>
                                </div>
                            }


                            <div className="bomb-button-container">
                                <button
                                    className="bomb-button"
                                    onPointerDown={handleBombButtonDown}
                                    onPointerUp={handleBombButtonUp}
                                >
                                    <img
                                        src={isBombButtonPressed ? blockOrange : blockBlue}
                                        alt="bomb-img"
                                        className="block-img"
                                    />
                                    <img
                                        src={bombImg}
                                        alt="bomb-2"
                                        className="bomb-img"
                                    />
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div class="joining-container">
                                <button onClick={() => handleJoinGame() }>
                                    <img src={joinGame } alt="join-game" />
                                </button>
                                {
                                    roomFull && <h1>Room is full</h1>
                                }
                        </div>
                    )

                    }
                </>

            ) : (
                <div>
                    Please rotate your device :)

                </div>
            )}
        </>


    );
}
